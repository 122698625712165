import text_cs from "./cs.js"
import text_en from "./en.js"

export default {
  data: {cs: text_cs, en: text_en},
  available: ["cs", "en"],
  availableLanguages: [
    {name: "Česky", value: "cs"},
    {name: "English", value: "en"},
  ],
  active: "en",
  default: "en",
  overrides: {},

  get(key, fill, forcedLang) {
    let lang = forcedLang ? forcedLang : this.active
    // console.log('Langs/get():', key, fill, forcedLang)
    let result = key

    // user overrides
    if (this.overrides[key]) {
      // console.log('Langs/get(): got override', this.overrides[key])
      result = this.overrides[key][lang]
    } else {
      if (this.data[lang] != null && this.data[lang].text[key] != undefined) {
        result = this.data[lang].text[key]
      }
    }
    if (fill) {
      for (let i = 0; i < fill.length; i++) {
        result = result.replace("%" + i, fill[i])
      }
    }
    return result
  },

  setLang(lang) {
    console.log("Selecting lang", lang)

    if (this.available.indexOf(lang) == -1) {
      // Administrationis not supporting this language
      return false
    }
    this.active = lang
    // // console.log('Selected lang', lang, this.data[this.active])
    return true
  },
}
